import * as React from "react"
import { navigate } from "gatsby"
import { useForm } from "react-hook-form"
import ErrorMessage from "./ui/error-message"
import { useStaticQuery, graphql } from "gatsby"
import { errorStyles, encode } from "../helpers"

import ButtonWithLoader from "./ui/button-with-loader"

const ContactUsForm = (props) => {

  const {
    formName,
    buttonClassName,
    buttonText = 'SUBMIT INFORMATION',
    wrapperButtonClassName = '',
  } = props

  const { data } = useStaticQuery(
    graphql`
      query {
        data: contentfulList(contentful_id: { eq: "2OVk78mnEdo2MGP3rrgGYF" } ) {
          textList {
            item
          }
        }
      }
    `
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" })

  const privateEmailDomains = (data.textList || []).map(({ item }) => item);
  const [alert, setalert] = React.useState(null)
  const [isLoading, setisLoading] = React.useState(false)
  const formEl = React.useRef(null)

  const onSubmit = (data, e) => {
    e.preventDefault()
    const form = e.target

    setisLoading(true)

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...data,
      }),
    })
      .then(() => {
        navigate("/poc-thank-you")
      })
      .catch(error => {
        setalert("Form could not be submitted. Please try again.")
        setisLoading(false)
        formEl.current.reset()
        console.log(error)
      })
  }

  const validateEmailIsNotPrivate = (email) => {
    const userEmailDomain = email.match(/@([^.]+)./)?.[1]
    const privateDomains = privateEmailDomains.includes(userEmailDomain);
    if (privateDomains) {
      return 'Please use your work email'
    }
  }

  return (
    <div className="relative">
      {alert && <p>{alert}</p>}

      <form
        ref={formEl}
        name={formName}
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        className="mb-12"
        onSubmit={handleSubmit(onSubmit)}>
        <div hidden>
          <label>
            Don’t fill this out: <input name="bot-field" />
          </label>
        </div>

        <fieldset className="form-columns-2">
          <div>
            <label htmlFor="firstName">First Name</label>
            <input
              type="text"
              name="firstName"
              id="firstName"
              style={errors.firstName && errorStyles}
              {...register("firstName", { required: true, maxLength: 80 })}
            />
            {errors.firstName?.type === "required" && <ErrorMessage />}
          </div>

          <div>
            <label htmlFor="lastName">Last Name</label>
            <input
              type="text"
              name="lastName"
              id="lastName"
              style={errors.lastName && errorStyles}
              {...register("lastName", { required: true, maxLength: 80 })}
            />
            {errors.lastName?.type === "required" && <ErrorMessage />}
          </div>
        </fieldset>

        <fieldset>
          <label htmlFor="email">Your Work Email</label>
          <input
            type="email"
            name="email"
            id="email"
            style={errors.email && errorStyles}
            {...register("email", { required: true, pattern: /^\S+@\S+$/i, validate: validateEmailIsNotPrivate })}
          />
          {errors.email?.message ? 
            (<ErrorMessage message={errors.email.message}/>)
            :
            (errors.email?.type === "required" && <ErrorMessage />)
          }
        </fieldset>

        <fieldset>
          <label htmlFor="phoneNumber">Phone number</label>
          <input
            type="number"
            name="phoneNumber"
            id="phoneNumber"
            style={errors.phoneNumber && errorStyles}
            {...register("phoneNumber", { required: true })}
          />
          {errors.phoneNumber?.type === "required" && <ErrorMessage />}
        </fieldset>
        
        <div className={wrapperButtonClassName}>
          <ButtonWithLoader 
            label={buttonText} 
            isLoading={isLoading} 
            className={buttonClassName}
          />
        </div>
      </form>
    </div>
  )
}

export default ContactUsForm
