import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import * as React from "react"

const Customers = (props) => {
  const className = "mb-8 mx-8 lg:mx-4"
  const {
    all
  } = props;

  const { data } = useStaticQuery(
    graphql`
       query {
         data: contentfulList(contentful_id: { eq: "6fQbjvSXjfnQ7HNI4WxSVw" } ) {
          imageList {
              title
              gatsbyImageData(
                  placeholder: NONE
                  quality: 100
              )
          }
        }
      }
    `
  )

  const customers = data.imageList || [];

  return (
    <div className="max-w-4xl mx-auto">
      <div className="flex flex-wrap items-end justify-center">
        {customers.map((customer, index) => {
          if (props[customer.title] || all) { 
            return <GatsbyImage
              key={index}
              image={customer.gatsbyImageData}
              alt={customer.title}
              className={className}
            />
          }
          return <></>
        })}
      </div>
    </div>
  )
}

export default Customers
